<template>
  <div class="d-flex align-items-start">
    <context-menu ref="context">
      <b-button
        @click="handleChangePeriod('years')"
        class="text-left px-3"
        :variant="period === 'years' ? 'primary' : 'light'"
      >
        <span>
          <b-icon class="mr-2" icon="file-spreadsheet" /> Anos consolidados
        </span>
      </b-button>
      <b-button
        @click="handleChangePeriod('quarters')"
        class="text-left px-3 line-height-1"
        :variant="period === 'quarters' ? 'primary' : 'light'"
      >
        <span> <b-icon class="mr-2" icon="table" /> Descritivo trimestral</span>
      </b-button>
    </context-menu>
    <b-card class="w-100 min-h-62 mobile-transparent">
      <Table :period="period" ref="table" />
    </b-card>
  </div>
</template>
<script>
import Table from "./Income.vue";

export default {
  components: {
    Table,
  },
  data() {
    return {
      selectedMenu: {
        url: "",
      },
      period: "years",
    };
  },
  methods: {
    handleChangePeriod(period) {
      this.period = period;
      this.$refs.context.close();
      this.$refs.table.getTable();
    },
  },
  created() {
    this.selectedMenu.url = this.$route.fullPath;
  },
};
</script>
